import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Img from 'gatsby-image';
import SEO from '../components/seo';
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import { setFullscreen } from "../components/store";

const responsive = {
	0: {
		items: 1,
	},
	600: {
		items: 2,
	},
	1024: {
		items: 3
	},
	1440: {
		items: 4
	}
};

const Realisations = connect(
	state => ({
		realisations: state.realisations
	})
)(
	({ data, realisations, dispatch }) => {
		let slide;

		return (
			<section className="realisations">
				<SEO
					title="Réalisations"
					description=""
				/>

				<AliceCarousel
					items={realisations.map((item, index) => (
						<div key={index} className="card" onClick={() => dispatch(setFullscreen(index))}>
							<Img fluid={data[item.image].childImageSharp.fluid} />
							<h4>{item.title}</h4>
						</div>
					))}
					responsive={responsive}
					dotsDisabled={true}
					buttonsDisabled={true}
					ref={el => (slide = el)}
				/>

				<div className="carousel-left" onClick={() => slide.slidePrev()}>&lt;</div>
				<div className="carousel-right" onClick={() => slide.slideNext()}>&gt;</div>
			</section>
		);
	}
);

export default Realisations;

export const query = graphql`
	query {
		foobar: file(relativePath: { eq: "foobar.png" }) {
			childImageSharp {
				fluid(maxWidth: 382, maxHeight: 528) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
